





























































































































































@import '../utilities/config';

.page__header__title {
  &::after {
    background-image: url(../assets/dashboard_gray.svg);
  }
}

.v-dashboard {
  &__salutation {
    margin-bottom: 25px;
  }

  &__col {
    padding-bottom: 30px;
  }

  &__box {
    border-radius: 7px;
    border: 1px solid gray('gray-150');
    padding: 35px 10px;
    height: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;

    &--gray {
      color: gray('white');
      background-color: gray('gray-400');
    }

    &--green {
      border-color: theme-color('primary');
    }

    &__text {
      padding: 0 10px;
      opacity: 1;

      a {
        &,
        &:hover,
        &:focus,
        &:active {
          color: inherit;
        }
      }

      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 17px;

      &--green {
        color: theme-color('primary');

        &:before {
          content: '';
          background-color: theme-color('primary');
          position: absolute;
          left: 15px;
          top: 28px;
          width: 5px;
          height: 40px;
        }
      }
    }

    &__notice {
      font-size: 11px;
      font-style: italic;
    }
  }

  &__title {
    font-size: 23px;
    font-weight: 500; //font-weight: 400;;
  }

  &__subtitle {
    font-size: 17px;
    color: gray('gray-400');
    font-weight: 500; //font-weight: 400;;
  }
}
